import * as React from "react";
import {useState} from "react";
import {themeDefault} from "../../MUI";
import {Box, Button, TextField, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {tl} from "../../utils";
import { ExpandMore } from "@material-ui/icons";

import styles  from "./styles.module.scss";

interface WebinarEventEndProps {
  link?: string;
  editable: boolean;
  onChange?: (link: string) => void;
}

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    lineHeight: "30px",
  },

  grey: {
    color: themeDefault.palette.grey[600],
  },
});

export const WebinarEventEnd = (
  props: WebinarEventEndProps,
) => {
  const classes = useStyles();

  const [link, setLink] = useState(props.link);
  const [savedLink, setSavedLink] = useState(props.link);
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
    event.key === "Enter" && handleSubmit();
  };

  const openSettings = () => {
    if (props.editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  };

  const handleCancel = () => {
    closeSettings();
    setLink(savedLink);
    setIsChanged(false);
  };

  const handleSubmit = (event?: any) => {
    event && event.preventDefault();
    closeSettings();
    props.onChange && props.onChange(link || '');
    setSavedLink(link || '');
  };

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      <Typography classes={{root: classes.root}}>
        {tl("Конец вебинара. Закрытие комнаты.")}
      </Typography>
      <Typography>
        {tl("Перенаправление на ссылку")}
      </Typography>
      {props.editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
            <ExpandMore/>
        </Box>
      }
      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxSettings}>
            <Box className={styles.changeBoxRow}>
              <Box maxWidth="300px" width="100%" pr="10px">
                <TextField
                  variant="outlined"
                  fullWidth
                  value={link}
                  onChange={(event: any) => {
                    setLink(event.target.value);
                    setIsChanged(true);
                  }}
                />
              </Box>
              <Typography classes={{root: classes.grey}}>{tl("Ссылка")}</Typography>
            </Box>
            <Box className={styles.changeBoxButtons}>
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  {tl("Отмена")}
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged}
              >
                {tl("Сохранить")}
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}