import React from "react";
import {useEffect, useState} from "react";
import Cleave from "cleave.js/react";
import {themeDefault} from "../../MUI";
import {Box, Button, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ExpandMore} from "@material-ui/icons";
import {getBoomstreamVideo, getVideoSourceTypeByLink, getVimeoPoster, getYoutubePoster, tl} from "../../utils";

import styles  from "./styles.module.scss";
import {EWebinarSourceType} from "../../types";

interface IVideo {
  type: string;
  src: string;
  time: number;
  videoLink?:string;
  poster?:string;
  apikey?:string;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    lineHeight: "30px",
  },

  grey: {
    color: themeDefault.palette.grey[600],
  },
  videoPreview: {
    width: 206,
    height: 116,
    border: '1px solid #D3D9DE',
    borderRadius: 2,
    opacity: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  timeInput: {
    "& input": {
      padding: "7px 9px",
      border: "1px solid #d3d3d3",
      borderRadius: "4px",
      boxSizing: "border-box",
      width: "100%",
    },

    "& input:focus": {
      border: "2px solid #3F53D9",
      outline: "none",
      padding: "7px 8px",
    },
  },
})
interface WebinarEventVideoProps {
  video: IVideo;
  editable: boolean;
  onChange?: (video: IVideo) => void;
}

export const WebinarEventVideo: React.FC<WebinarEventVideoProps> = ({
  video, editable, onChange
}) => {

  const classes = useStyles();

  const convertSecondsToString = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds / 60 - (h * 60));
    const s = seconds - (h * 3600) - (m * 60);
    const hasZero = (num: number) => num < 10 ? "0" : "";

    return `${hasZero(h)}${h}:${hasZero(m)}${m}:${hasZero(s)}${s}`;
  };

  const convertStringToSeconds = (stringTime: string) => {
    const arr = stringTime.split(":").map(str => Number(str));
    return arr[0] * 3600 + arr[1] * 60 + arr[2];
  };

  const [source, setSource] = useState(video.src);
  const [time, setTime] = useState(convertSecondsToString(video.time || 0));
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [sourceType, setSourceType] = useState(video.type);
  const [videoLink, setVideoLink] = useState('');
  const [poster, setPoster] = useState('');
  const [apiKey, setApiKey] = useState(video.apikey);
  const [bsApiError, setBSApiError] = useState('');

  const isCompleteTime = (time: string) => {
    if (time.length !== 8) return false;
    if (time.split(":").length !== 3) return false;
    return true;
  };

  const updateSource = (evt: any) => {
    const type = getVideoSourceTypeByLink(evt.target.value);
    if(type === EWebinarSourceType.YOUTUBE){
      const poster = getYoutubePoster(evt.target.value);
      if(poster) {
        setPoster(poster);
      }
    }
    if(type === EWebinarSourceType.VIMEO) {
      getVimeoPoster(evt.target.value).then((poster) => {
        setPoster(poster);
      });
    }
    setSource(evt.target.value);
    setIsChanged(true);
    setSourceType(type);
  };
  function getPosters(src: string) {
    const type = getVideoSourceTypeByLink(src);
    switch (type) {
      case EWebinarSourceType.YOUTUBE:
        setPoster(getYoutubePoster(src))
        break;
      case EWebinarSourceType.VIMEO:
        getVimeoPoster(src).then((poster) => {
          setPoster(poster);
        });
        break;
      case EWebinarSourceType.BOOMSTREAM:
        if(apiKey) {
          getBoomstreamVideo(src,apiKey)
            .then((meta) => {
              if(meta) {
                setPoster(meta.poster)
              }
            })
            .catch((e) => {
              if (e.message === 'api') {
                setBSApiError('Неправильный api-ключ');
              }
            });
        }
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    if(source) {
      getPosters(source);
    }
  }, []);


  const getVideoLink = async (apikey?: string) => {
    setApiKey(apikey);
    if (!apikey) {
      return;
    }

    getBoomstreamVideo(source,apikey)
      .then((result)=>{
        if(result !== null){
          setVideoLink(result.videoLink);
          setPoster(result.poster);
          setApiKey(apikey);
          setBSApiError('')
        }
      }).catch((e) => {
        if (e.message === 'api') {
          setBSApiError('Неправильный api-ключ');
        }
      });
  };

  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
    event.key === "Enter" && handleSubmit();
  };

  const openSettings = () => {
    if (editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  };

  const handleCancel = () => {
    closeSettings();
    setSource(video.src);
    setIsChanged(false);
  };

  const handleSubmit = (event?: any) => {
    event && event.preventDefault();
    closeSettings();
    onChange && onChange({
      ...video,
      src: source,
      type:sourceType,
      time: convertStringToSeconds(time),
      videoLink:videoLink,
      poster:poster,
      apikey:apiKey
    });
  };

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      <Typography className={classes.title}>
        {tl("Запуск видео по ссылке.")}
      </Typography>
      {poster && <div className={classes.videoPreview} style={{
        backgroundImage: `url('${poster}')`, 
       
        }} 
      />}
      {editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
            <ExpandMore/>
        </Box>
      }
      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxSettings}>
            <Box className={styles.changeBoxRow}>
              <Box maxWidth="300px" width="100%" pr="10px">
                <TextField
                  variant="outlined"
                  fullWidth
                  value={source}
                  onChange={updateSource}
                />
              </Box>
              <Typography classes={{root: classes.grey}}>{tl("Ссылка")}</Typography>
            </Box>
            {sourceType === EWebinarSourceType.BOOMSTREAM && (
              <Box className={styles.changeBoxRow}>
                <Box maxWidth="300px" width="100%" pr="10px">
                <TextField
                  variant="outlined"
                  fullWidth
                  value={apiKey}
                  error={!!bsApiError}
                  helperText={bsApiError}
                  placeholder={tl('Вставьте API-ключ')}
                  onChange={(evt: any) => {
                    getVideoLink(evt.target.value);
                  }}
                />
                </Box>
                <Typography classes={{root: classes.grey}}>{tl("API-ключ")}</Typography>
              </Box>
            )}
            <Box className={styles.changeBoxRow}>
              <Box className={classes.timeInput} maxWidth="185px" pr="10px">
                <Cleave
                  value={time}
                  options={{
                    time: true,
                    timePattern: ['h', 'm', 's']
                  }}
                  onChange={(event: any) => {
                    setTime(event.target.value);
                    setIsChanged(true);
                  }}
                />
              </Box>
              <Typography className={classes.grey}>{tl("Время старта видео")}</Typography>
            </Box>
            <Box className={styles.changeBoxButtons}>
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  Отмена
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged || !isCompleteTime(time)}
              >
                Сохранить
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}